import React , {useEffect}from "react";
import successImg from "./img/4.png"
import './App.css';
import {useHistory} from "react-router-dom";

export default function Success() {
    let history = useHistory()
    useEffect(() => {
        setTimeout(() => {
            history.goBack()
        }, 3000)
    })
    return (
        <div className={"successPage"}>
            <header className="Success-header">
                <img src={successImg} className="success" alt="success" />
                <h2 className={"successText"}>
                    YOU'VE GOT THE GREEN LIGHT TO CRUSH THIS WORKOUT CHAMPION!
                </h2>
            </header>
        </div>
    )
}
