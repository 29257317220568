import React, {useEffect} from "react";
import failedImg from "./img/1.png"
import './App.css';
import {useHistory} from "react-router-dom";
export default function Failed() {
    let history = useHistory()
    useEffect(() => {
        setTimeout(() => {
            history.goBack()
        }, 3000)
    })
    return (
        <div className={"failedPage"}>
            <header className="Failed-header">
                <img src={failedImg} className="failed" alt="failed" />
                <h2>
                    OOPS SOMETHING WENT WRONG!
                </h2>
                <p>
                    Please see our Front of House team before entering!
                </p>
            </header>
        </div>
    )
}
