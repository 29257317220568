import logo from './img/logo.png';
import Success from "./success";
import Failed from "./failed";
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";

function App() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/failed">
                        <Failed/>
                    </Route>
                    <Route path="/success">
                        <Success/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

function Home() {
    let history = useHistory()
    const today = new Date()
    const date = "" + today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
    const [mobile, setMobile] = useState("")
    const [block, setBlock] = useState(false)
    const summit = () => {
        if (mobile.length > 9) {
            setBlock(true)
            fetch(`https://manage.gymvue.com.au/api/ext-entry-log?%s?ts=${date}&door=maindoor&card=${mobile}&gymkey=Zdm42ahycYB6rabaiCi0uQtDYIVnznEo&outcome=granted&note= `)
            .then((res) => res.json())
            .then(res => {
            if (res.status === "successful_check_in" && res.current_balance < 1) {
                history.push("/success");
            } else {
                history.push("/failed");
            }
        })
        }
    }
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <span className={"t1"}>
            Welcome To Olympia!
          </span>
                <span className={"p1"}>
            Please enter your number below to check in.
          </span>
                <div className="inputBox">
                    <input
                        className={"mobileInput"}
                        type='tel'
                           value={mobile}
                           onChange={(e) => {
                               setMobile(e.target.value)
                           }}
                    ></input>
                    <input
                        className={"submit"}
                        disabled={block}
                        type='button'
                        value={"Check In"}
                        onClick={summit}
                    ></input>
                </div>
            </header>

        </div>
    );
}

export default App;
